export const styles = `
  .body {
    padding: 25px 20px;
    background-color: white;
    font-family: Arial, sans-serif;
  }
  .header {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: white;
  }
  .coluna {
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
  }
  .title {
    align-items: center;
    width: 60%;
  }

  .center {
    text-align: center;
    margin: 0 auto;
  }

  .logo {
    width: 25%;
  }

  .logoagroindustria {
    width: 20%;
    text-align: right;
  }

  .content {
    font-size: 12px;
    background-color: white;
  }

  .group {
    margin-bottom: 20px;
    page-break-inside: avoid !important;
  }

  .linha {
    border-left: 1px solid #333;
    border-right: 1px solid #333;
    display: flex;
    border-bottom: 1px solid #333;
    flex-direction: row;
    page-break-inside: avoid !important;
  }

  .linha_row {
    flex-direction: column !important;
    padding: 8px;
  }

  .box {
    border: 1px solid black;
    padding: 0.5rem;
    display: inline-flex;
  }

  .conform {
    display: flex;
    width: 100%;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .label {
    width: 95%;
  }

  .icon {
    width: 3%;
    margin-right: 1px;
    font-size: 20px;
  }

  .icon-x {
    margin-right: 8px;
  }

  .bt {
    border-top: 1px solid #333;
  }
  .bb {
    border-bottom: 1px solid #333;
  }
  .bl {
    border-left: 1px solid #333;
  }
  .br {
    border-right: 1px solid #333;
  }
  .c10 {
    width: 10%;
  }
  .c15 {
    width: 15%;
  }
  .c16 {
    width: 16%;
  }
  .c20 {
    width: 20%;
  }
  .c25 {
    width: 25%;
  }
  .c33 {
    width: 33.33%;
  }
  .c40 {
    width: 40%;
  }
  .c45 {
    width: 45%;
  }
  .c50 {
    width: 50%;
  }
  .c60 {
    width: 60%;
  }
  .c95 {
    width: 95%;
  }
  .c100 {
    width: 100%;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  #fazenda {
    font-size: 16px;
    font-weight: 700;
  }

  .image-assinatura {
    margin-bottom: -25px;
    margin-left: 100px;
    align-items: center;
    align-content: center;
    justify-content: center;
    transform: rotate(90deg);
  }

  .horizontal-center {
    align-items: center;
  }

  .multiples-images {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .column-image {
    width: 47%;
  }

  .image {
    object-fit: contain;
  }

  .vertical-line {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .dont-break-out {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #333;
    text-align: left;
    padding: 8px;
  }

  @page {
    height: 85%;
    margin-bottom: 10px;
  }

  @media print {
    div.divFooter {
      width: 100%;
      height: 20px;
      position: fixed;
      text-align: center;
      font-size: 12px;
      padding-top: 10px;
      bottom: 0;
    }
  }
`

export const draftStyle = `
body {
  min-height: 100vh;
  position: relative;
  margin: 0;
}

body:before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
  background:
    url('data:image/svg+xml;utf8,<svg style="transform:rotate(-45deg);" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 70 40"><text x="0" y="25" fill="%23000">Rascunho </text></svg>')
    0 0/100% 100vh;
}
`
