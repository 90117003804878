<template>
  <v-card>
    <DateInputs
      ref="dataInputsReports"
      v-model="reports"
      :report-type-id="reportTypeId"
      :draft="draft"
      @searching="loading = $event"
    />
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="reports"
      :headers="headers"
      :actions="actions"
      item-key="CodRelatorio"
      :slots="[
        'item.actions',
        'item.DataAtendimento',
        'item.sincronized',
        'item.ValidacaoId',
      ]"
      :search-init="search"
      :search.sync="search"
    >
      <template v-slot:[`item.DataAtendimento`]="{ item }">
        {{ moment(item.DataAtendimento).format('DD/MM/YYYY') }}
      </template>
      <template v-slot:[`item.sincronized`]="{ item }">
        {{ moment(item.sincronized).format('DD/MM/YYYY') }}
      </template>

      <template v-slot:[`item.ValidacaoId`]="{ item }">
        <StatusView :report-item="item" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-center align-center">
          <v-checkbox
            v-model="selectedReports"
            :value="item"
            color="primary"
            dense
          ></v-checkbox>
          <ReportShow :report-type-id="reportTypeId" :report="item" />
          <v-btn icon @click="deleteReport(item)">
            <v-icon dense color="error">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:btnCadastro>
        <v-btn
          color="green darken-1"
          dark
          class="rounded-lg"
          :loading="loadingSheet"
          @click="exportSheet"
        >
          <v-icon class="mr-2">mdi-file-excel</v-icon>

          <span style="font-size: 16px; font-weight: 600">Exportar</span>
        </v-btn>
      </template>

      <template v-if="selectedReports.length >= 1" v-slot:print>
        <v-card-actions class="justify-center">
          <div class="pa-4">
            <v-checkbox
              v-model="printValidation"
              label="Imprimir validação"
            ></v-checkbox>
          </div>
          <v-btn
            class="ml-3 rounded-lg"
            color="primary darken-1"
            :loading="loadingPDF"
            @click="exportSelectedReports"
          >
            <v-icon class="mr-2">mdi-file-pdf-box</v-icon>

            <span style="font-size: 16px; font-weight: 600"
              >Imprimir todos</span
            >
          </v-btn>
          <v-btn
            class="ml-3 rounded-lg"
            color="green darken-1"
            dark
            :loading="loadingSheet"
            @click="exportSelectedSheet"
          >
            <v-icon class="mr-2">mdi-file-excel</v-icon>

            <span style="font-size: 16px; font-weight: 600"
              >Exportar todos</span
            >
          </v-btn>
        </v-card-actions>
      </template>
    </crud-list>
    <StatusView />

    <div class="hidden-graph">
      <GraphReport v-if="step" id="graph-report" :step="step" />
    </div>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import api from '../../../api/api'
import Toast from '../../../plugins/toast'
import ProjectStructure from '../../../services/api/ProjectStructure'
import ReportType from '../../../services/api/reports'
import PDFGenerator from '../../../services/StructureReport/Pdf/PDFGenerator'
import SheetGenerator from '../../../services/StructureReport/Sheet/SheetGenerator'
import GraphReport from './components/GraphReport.vue'
import ReportShow from './components/ReportShow.vue'
import DateInputs from './DateInputs'
import StatusView from './StatusView.vue'
import Reports from '../../../services/api/reports'

export default {
  name: 'ReportsLists',

  components: {
    DateInputs,
    StatusView,
    ReportShow,
    GraphReport,
  },

  props: {
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      loadingSheet: false,
      search: '',
      reportTypeId: null,

      reportDialog: false,
      loadingPDF: false,
      selectedReport: null,

      printValidation: false,

      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'ValidacaoId',
          width: '120px',
          filterable: true,
        },
        {
          text: 'Consultor',
          align: 'center',
          sortable: true,
          value: 'Consultor',
          width: '200px',
          filterable: true,
        },
        {
          text: 'Produtor',
          align: 'center',
          sortable: true,
          value: 'Produtor',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Fazenda',
          align: 'center',
          sortable: true,
          value: 'Fazenda',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'center',
          sortable: true,
          text: 'Agroindústria',
          value: 'Agroindustria',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'center',
          sortable: true,
          text: 'Número da Visita',
          value: 'NumeroVisita',
          width: '80px',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Data da Visita',
          value: 'DataAtendimento',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Hora Início',
          value: 'HoraInicio',
          width: '20px',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Hora Fim',
          value: 'HoraFim',
          width: '20px',
        },
        this.$user.get().role !== 'gestor'
          ? {
              align: 'center',
              sortable: true,
              text: 'Data da sincronização',
              value: 'sincronized',
              width: '20px',
            }
          : '',
      ],
      actions: [
        {
          text: 'Visualizar',
          icon: 'mdi-eye',
          color: 'primary',
          click: this.view,
        },
        {
          text: 'Excluir',
          icon: 'mdi-delete',
          color: 'error',
          click: this.delete,
        },
      ],
      reports: [],

      selectedReports: [],

      step: null,
    }
  },

  computed: {
    ...mapState(['relatorio_search']),
    ...mapState(['relatorio_dataInicio', 'relatorio_dataFim']),
    ...mapState('report', ['structure']),

    dataInicio: {
      get() {
        return this.$store.state.relatorio_dataInicio
          ? this.$store.state.relatorio_dataInicio
          : this.moment().subtract(1, 'months').toISOString(true).substr(0, 10)
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_DATAINICIO', val)
      },
    },
    dataFim: {
      get() {
        return this.$store.state.relatorio_dataFim
          ? this.$store.state.relatorio_dataFim
          : this.moment().toISOString(true).substr(0, 10)
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_DATAFIM', val)
      },
    },
  },

  watch: {
    $route() {
      this.reportTypeId = this.$route.params.id
      this.$refs.dataInputsReports.getHeaders(this.$route.params.id)
      this.LOAD_STRUCTURE(this.reportTypeId)
    },
  },

  created() {
    this.reportTypeId = this.$route.params.id
    this.LOAD_STRUCTURE(this.reportTypeId)
  },

  methods: {
    ...mapActions('report', ['LOAD_STRUCTURE', 'SET_REPORT']),

    view(item) {
      this.selectedReport = item
      this.reportDialog = true
    },

    deleteReport(item) {
      Swal.fire({
        title: 'Deseja realmente excluir este relatório?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,

        preConfirm: async () => {
          await api.delete.report(item.CodRelatorio).then(() => {
            this.reports = this.reports.filter(
              report => report.CodRelatorio !== item.CodRelatorio,
            )
          })
        },
      })
        .then(result => {
          if (result.isConfirmed) {
            Toast().fire({
              icon: 'success',
              title: 'Relatório excluído com sucesso',
            })
          }
        })
        .catch(() => {
          Toast().fire({
            icon: 'error',
            title: 'Erro ao excluir relatório',
          })
        })
    },

    async exportSheet() {
      this.loadingSheet = true

      try {
        const sheet = new SheetGenerator(this.structure, this.reports)
        await sheet.create(this.structure.title)
      } catch (error) {
        Toast().fire({
          icon: 'error',
          title: 'Erro ao exportar planilha',
        })
      } finally {
        this.loadingSheet = false
      }
    },

    async exportSelectedSheet() {
      this.loadingSheet = true

      try {
        const sheet = new SheetGenerator(this.structure, this.selectedReports)
        await sheet.create(this.structure.title)
      } catch (error) {
        Toast().fire({
          icon: 'error',
          title: 'Erro ao exportar planilha',
        })
      } finally {
        this.loadingSheet = false
      }
    },

    async exportSelectedReports() {
      try {
        this.loadingPDF = true
        let layout = await ReportType.getLayout({ id: this.reportTypeId })
        layout = JSON.parse(layout.data.dados)

        let { data: structure } = await ProjectStructure.find({
          id: this.reportTypeId,
        })

        for (const id of this.selectedReports) {
          this.step = null
          const { data: report } = await ReportType.getReport({
            id: id.CodRelatorio,
          })

          this.SET_REPORT(report)

          structure.steps.forEach(step => {
            if (step.title.includes('Composição do rebanho')) {
              this.step = step
            }

            if (step.title.includes('Boas práticas em qualidade do leite')) {
              this.step = step
            }
          })

          let validation = this.printValidation
            ? await Reports.getValidation({ id: id.CodRelatorio })
            : null

          const pdfGenerator = new PDFGenerator(
            structure,
            layout,
            report,
            validation ? validation.data : null,
          )
          await pdfGenerator.create(this.structure.title)
        }
      } catch {
        Toast().fire({
          icon: 'error',
          title: 'Erro ao exportar PDF',
        })
      } finally {
        this.loadingPDF = false
      }
    },
  },
}
</script>

<style>
.hidden-graph {
  position: absolute;
  left: -9999px;
  top: -9999px;
}
</style>
