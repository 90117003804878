import html2canvas from 'html2canvas'
import moment from 'moment'
import { convertNumberBR } from '../../../utils/masks'
import { HeaderPDF } from './HeaderPDF'
import { ReportPrintBase } from './ReportPrintBase.js'
import { checkUserType } from './components/Signature'

export function evalCalculated(fn) {
  return new Function('return ' + fn)()
}

export class ReportPrint extends ReportPrintBase {
  constructor(structure, report, layout, validation, isDrift = false) {
    super()
    this.fields = report
    this.structure = structure
    this.layout = layout
    this.validation = validation
    this.isDrift = isDrift

    this.title = this.structure.title
  }

  async createHtmlStructure() {
    await this.generateStructure()
    const html = await this.loadHtmlBase()
    return html
  }

  getFieldValue = key => {
    let data = this.fields.find(item => item.key === key)

    if (!data) {
      data = this.getSpecialStaticValue(key)
    }

    return data?.value && data?.value !== '0' ? data.value : ''
  }

  getValueField = value => {
    const field = this.fields.find(item => item.value === value)

    return field?.value || ''
  }

  getFieldItem = key => {
    let data = this.fields.find(item => item.key === key)

    if (!data) {
      data = this.getSpecialStaticValue(key)
    }

    return data || {}
  }

  setReportStructure(reportStructure) {
    this.reportStructure = reportStructure
    this.setTitle(reportStructure.title)
  }

  getFieldReportStructure(key) {
    for (const step in this.reportStructure.steps) {
      const fields = step.fields
      for (const field of fields) {
        if (field.name === key) {
          return field
        }
      }
    }
  }

  getRuleValue(key, rules) {
    const rule = rules.find(r => r.key === key)
    if (rule) {
      switch (rule.key) {
        case 'required':
        case 'permitirDataFutura':
        case 'validateCPF':
        case 'validateCNPJ':
          return rule.value === 'true'
        case 'maxLength':
          return Number(rule.value)
        case 'fieldCalculated':
          return rule.value
      }
    }
  }

  setFieldValue(key, value) {
    const field = this.fields.find(item => item.key === key)

    if (field) {
      field.value = value
    }
  }

  addValidations() {
    let contentHtml = ''
    let validations = this.validation

    if (!validations) return contentHtml

    contentHtml += `<div class="subtitle">
                      <h3>Histórico de Validações</h3>
                    </div>`

    for (const validation of validations) {
      contentHtml =
        contentHtml +
        `<div class="linha bt">
            <div class="coluna c100">
                <span>Autor: ${validation.UserName}</span>
                <b class="dont-break-out" style="word-wrap: break-word;" id="validacao${validation.Id}">${validation.Descricao}</b>
            </div>
        </div>`
    }

    return contentHtml
  }

  getValueOfMultInsertForm(fields, key) {
    const data = fields.find(item => item.key === key)
    return data?.value || ''
  }

  async generateStructure() {
    this.structure.steps[0].fields = HeaderPDF
    const steps = this.structure.steps

    let contentHtml = ''

    for (const step of steps) {
      if (!this.isValueInSubFields(step.fields)) continue

      contentHtml = contentHtml + this.addStartGroup()
      contentHtml = contentHtml + this.addSubtitle(step.title)

      if (step.title.includes('Boas práticas em qualidade do leite')) {
        contentHtml += this.addOnlyPieChart()
      }

      if (step.title.includes('Composição do rebanho')) {
        const element = document.getElementById('chart-rebanho')

        if (element) {
          const canvas = await html2canvas(element)

          const img = canvas.toDataURL()

          contentHtml += `
            <div>
              <img src="${img}" alt="Composição do rebanho" />
            </div>
          `
        }
      }

      if (step?.fields) {
        contentHtml = contentHtml + this.addComponents(step.fields)
      }

      if (step?.fields1) {
        contentHtml = contentHtml + this.addComponents(step.fields1)
      }
      contentHtml = contentHtml + this.addEndGroup()
    }
    contentHtml += this.addSignatures()

    contentHtml = contentHtml + this.addStartGroup()
    contentHtml = contentHtml + this.addSubtitle('Imagems em anexo')
    contentHtml += this.addImages()
    contentHtml = contentHtml + this.addEndGroup()
    contentHtml += this.addValidations()

    this.content = contentHtml
  }

  getBase64Image = async image => {
    const response = await fetch(image)
    const blob = await response.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
      reader.readAsDataURL(blob)
    })
  }

  loadRodape() {
    const now = new Date()
    const created = `Gerado em ${moment(now).format('DD/MM/YYYY, HH:mm')}`
    this.setFooter(`${created}, www.milkplan.com.br`)
  }

  fieldCalculated = value => {
    let formula = value
    try {
      this.fields.forEach(element => {
        if (
          formula.includes(`{@${element.key}}`) &&
          this.getFieldValue(element.key)
        ) {
          formula = formula.replaceAll(
            formula,
            `{@${element.key}}`,
            this.getFieldValue(element.key),
          )
        }
      })
      return convertNumberBR(evalCalculated(formula))
    } catch {
      return ''
    }
  }

  handleSignatureWithName(column) {
    column.value = this.getFieldValue(column.key)
    column.sufixo =
      this.staticValueAssign(column.sufixo) || this.getFieldValue(column.sufixo)
  }

  handleDefaultColumn(column) {
    if (column.type === 'staticText') {
      column.label = this.staticTextAssign(column)
      column.value = '1'
    } else {
      column.value = this.getFieldValue(column.key)
    }
  }

  handleSpecialKeys(column) {
    switch (column.key) {
      case 'fazenda':
        column.value = this.getFieldValue('fazenda') || ''
        break
      case 'nomeProdutor':
        column.value = this.getFieldValue('produtor') || ''
        break
      case 'cidade':
        column.value = `${
          this.getFieldValue('cidade') ||
          '' - this.getFieldValue('estado') ||
          ''
        }`
        break
      case 'nomeConsultor':
        column.value = column.value || this.getFieldValue('nomeConsultor')
        break
      case 'numeroVisita':
        column.value = this.getFieldValue('numeroVisita') || ''
        break
      case 'agroindustria':
        column.value = this.getFieldValue('agroindustria') || ''
        break
      case 'matriculaProdutor':
        column.value = this.getFieldValue('matricula') || ''
        break
    }
  }

  staticTextAssign(column) {
    if (!column.value) return column.label

    const vStatic = JSON.parse(column.value)
    if (!Array.isArray(vStatic)) return column.label

    return vStatic.reduce(
      (label, v) => label.replace(`{${v}}`, this.staticValueAssign(v)),
      column.label,
    )
  }

  staticValueAssign(key) {
    return this.getFieldValue(key) || this.getSpecialStaticValue(key)
  }

  getSpecialStaticValue(key) {
    switch (key) {
      case 'nomeProdutor':
        return this.getFieldItem('produtor').value || ''
      case 'cidade':
        return this.getFieldItem('cidade').value || ''
      case 'nomeConsultor':
        return this.getFieldItem('nomeConsultor').value || ''
      case 'responsible':
        return (
          this.getFieldItem('nomeResponsavel') || this.getFieldItem('produtor')
        )
      case 'matriculaProdutor':
        return this.getFieldItem('matricula') || ''
    }
  }

  convertColumnWidth(qtdeColumns) {
    const widths = { 2: 'c50', 3: 'c33', 4: 'c25', 5: 'c20', 6: 'c16' }
    return widths[qtdeColumns] || ''
  }

  recursiveFields = (fields, callback) => {
    for (const field of fields) {
      callback(field)

      if (field.fields) {
        this.recursiveFields(field.fields, callback)
      }

      if (field.fields1) {
        this.recursiveFields(field.fields1, callback)
      }
    }
  }

  recursiveAsyncFields = async (fields, callback) => {
    for (const field of fields) {
      await callback(field)

      if (field.fields) {
        this.recursiveFields(field.fields, callback)
      }

      if (field.fields1) {
        this.recursiveFields(field.fields1, callback)
      }
    }
  }

  addComponents = fields => {
    let contentHtml = ''
    let nextAdded = false

    fields.forEach((field, index) => {
      if (this.shouldSkipField(field)) return

      const value = this.getFieldValue(field.name)
      const isValidValue = this.validValue(value)
      const isValidComponentIsNotValue = this.isValidComponentWithoutValue(
        field,
      )

      if (!isValidValue && !isValidComponentIsNotValue) return

      if (nextAdded && isValidComponentIsNotValue) {
        nextAdded = false
      }

      if (field.name == 'horaTermino' && field.type == 'numeric') {
        return
      }

      if (
        field.componentType == 'selection' ||
        field.componentType == 'selectionOption' ||
        field.componentType == 'buttonSelect' ||
        field.componentType == 'multiInsertForm'
      ) {
        nextAdded = false
      }

      if (nextAdded) {
        nextAdded = false
        return
      }

      if (this.isValidInput(field)) {
        contentHtml += this.addInputFields(fields, field, index)
        nextAdded = true
      } else {
        contentHtml += this.addFieldComponent(field, index)
      }
    })

    this.loadRodape()

    return contentHtml
  }

  shouldSkipField = field => {
    return (
      field.componentType === 'btnAssinatura' ||
      field.label.toLowerCase().includes('assinatura')
    )
  }

  isValidComponentWithoutValue = field => {
    return ['groupField', 'groupRadioButton', 'groupCheckbox'].includes(
      field.componentType,
    )
  }

  isValidInput = field => {
    return [
      'inputText',
      'timePiker',
      'datePiker',
      'staticValue',
      'select',
      'text',
    ].includes(field.componentType)
  }

  addInputFields = (fields, field, index) => {
    let contentHtml = this.addStartLine('bt')
    field.props = 'c50'
    contentHtml += this.addInputText(field)

    const nextField = fields[index + 1]
    if (nextField && this.isValidInput(nextField)) {
      nextField.props = 'c50 bl'
      contentHtml += this.addInputText(nextField)
    }

    contentHtml += this.addEndLine()
    return contentHtml
  }

  addFieldComponent = (field, index) => {
    switch (field.componentType) {
      case 'textArea':
        return this.addTextArea(field)
      case 'groupField':
      case 'groupRadioButton':
      case 'groupCheckbox':
      case 'someCheckbox':
        return this.addGrupField(field)
      case 'checkbox':
        return this.addCheckbox(field)
      case 'multiInsertForm':
        return this.addMultiInsertForm(field, index)
      case 'selection':
        return this.addSelection(field)
      case 'selectionOption':
      case 'buttonSelect':
        return this.selectionOption(field)
      case 'simpleInput':
      case 'inputText':
        return this.addSimpleInput(field)
      default:
        return ''
    }
  }

  addInputText = field => {
    const value = this.getFieldValue(field.name)

    const params = {
      value,
      label: field.label,
      type: field.componentType,
      fieldName: field.name,
      prefix: '',
      suffix: '',
      props: field.props,
    }

    return this.addColumn(params)
  }

  addGrupField(field) {
    let contentHtml = ''

    if (!this.isValueInSubFields(field.fields)) {
      return ''
    }

    contentHtml = contentHtml + this.addStartLineRow('bt')
    contentHtml =
      contentHtml +
      this.addColumn({
        label: `<b>${field.label}</b>`,
        type: 'label',
      })

    if (field.fields) {
      contentHtml = contentHtml + this.addComponents(field.fields)
    }

    if (field.fields1) {
      contentHtml = contentHtml + this.addComponents(field.fields1)
    }

    contentHtml = contentHtml + this.addEndLine()

    return contentHtml
  }

  addTextArea(field) {
    let contentHtml = ''

    const value = this.getFieldValue(field.name)

    if (!value) return ''

    contentHtml = contentHtml + this.addStartLine('bt')

    const params = {
      value,
      label: field.label,
      type: field.componentType,
      fieldName: field.name,
      prefix: '',
      suffix: '',
      props: field.props,
    }

    contentHtml = contentHtml + this.addColumn(params)
    contentHtml = contentHtml + this.addEndLine()

    return contentHtml
  }

  addMultiInsertForm(field, index) {
    let contentHtml = ''

    const rawValues = this.getFieldValue(field.name)

    if (!rawValues?.length) return ''

    const values = JSON.parse(rawValues)

    const headers = field.fields.map(item => {
      return {
        label: item.label,
        key: item.name,
      }
    })

    contentHtml =
      contentHtml + this.addTableHeader(field, headers, values, index)

    return contentHtml
  }

  isValueInSubFields = fields => {
    let hasValue = false

    const recursiveFields = fields => {
      for (const field of fields) {
        const value = this.getFieldValue(field.name)

        if (this.validValue(value)) {
          hasValue = true
          return
        }

        if (field.fields && field.fields.length > 0) {
          recursiveFields(field.fields)
          if (hasValue) return
        }
      }
    }

    recursiveFields(fields)
    return hasValue
  }

  addCheckbox = field => {
    let contentHtml = ''

    const value = this.getFieldValue(field.name)

    if (!value) return ''

    contentHtml = contentHtml + this.addStartLine('bt')

    const params = {
      label: field.label,
      type: field.componentType,
      fieldName: field.name,
      prefix: '',
      suffix: '',
      props: field.props,
    }

    contentHtml = contentHtml + this.addColumn(params)
    contentHtml = contentHtml + this.addEndLine()

    return contentHtml
  }

  validValue(value) {
    return value && value !== '' && value !== '0' && value.length
  }

  addSignatures() {
    const signaturesFields = []

    const callback = field => {
      if (field.componentType === 'btnAssinatura') {
        signaturesFields.push(field)
      }
    }

    this.recursiveFields(this.structure.steps, callback)

    if (!signaturesFields.length) return ''

    let contentHtml = ''

    contentHtml = contentHtml + this.addStartLine('bt ')

    signaturesFields.forEach((field, index) => {
      const value = this.getFieldValue(field.name)

      if (!value) return

      if (!field.props) {
        field.props = ''
      }

      field.props += 'c50'

      if (index > 0) {
        field.props += ' bl'
      }

      const user = checkUserType(field.label, this.getFieldValue)

      contentHtml =
        contentHtml +
        this.generateSignatureContent({
          label: field.label,
          value,
          type: 'signatureWithName',
          props: field.props,
          sufixo: user ?? '',
        })
    })

    contentHtml = contentHtml + this.addEndLine()

    return contentHtml
  }

  addImages() {
    const imagesFields = []

    const callback = field => {
      if (field.componentType === 'multipleImageSelect') {
        imagesFields.push(field)
      }
    }

    this.recursiveFields(this.structure.steps, callback)

    if (!imagesFields.length) return ''

    let contentHtml = ''

    imagesFields.forEach(field => {
      const value = this.getFieldValue(field.name)

      if (!value) return

      const images = JSON.parse(value)

      if (!images.length) return

      if (images.length)
        if (!field.props) {
          field.props = ''
        }

      contentHtml = contentHtml + this.addStartLine('bt mb-10')

      contentHtml =
        contentHtml +
        this.generateMultipleImagesContent({
          label: field.label,
          value: images,
          type: 'multipleImageSelect',
          props: field.props,
          sufixo: field.sufixo,
        })

      contentHtml = contentHtml + this.addEndLine()
    })

    return contentHtml
  }

  addSelection(field) {
    let contentHtml = ''

    const value = this.getFieldValue(field.name)

    if (!value) return ''

    contentHtml = contentHtml + this.addStartLine('bt')

    const params = {
      label: field.label,
      type: field.componentType,
      fieldName: field.name,
      prefix: '',
      suffix: '',
      props: field.props,
    }

    if (field.fields) {
      field.fields.forEach(subField => {
        const value = this.getFieldValue(subField.name)

        if (!this.validValue(value)) return

        let otherHtml = ''

        if (subField.fields) {
          otherHtml += this.addStartGroup('bt')
          otherHtml += this.addComponents(subField.fields)
          otherHtml += this.addEndLine()
        }

        contentHtml += this.addColumn({
          ...params,
          value: subField.label,
          html: otherHtml,
        })

        // contentHtml = contentHtml + this.addEndLine();
      })
    }

    contentHtml = contentHtml + this.addEndLine()

    return contentHtml
  }

  addText(label) {
    return `<p>${label}</p>`
  }

  selectionOption(field) {
    let contentHtml = ''

    const value = this.getFieldValue(field.name)

    if (!value) return ''

    contentHtml = contentHtml + this.addStartLine('bt')

    const params = {
      value,
      label: field.label,
      type: field.componentType,
      fieldName: field.name,
      prefix: '',
      suffix: '',
      props: field.props,
    }

    contentHtml = contentHtml + this.addColumn(params)

    if (field.fields) {
      contentHtml = contentHtml + this.addComponents(field.fields)
    }

    contentHtml = contentHtml + this.addEndLine()

    return contentHtml
  }

  addSimpleInput(field) {
    return this.addInputText(field)
  }

  addOnlyPieChart() {
    let contentHtml = ''

    const requiredConform = this.getFieldValue('relevancia_1')
    const relevantConform = this.getFieldValue('relevancia_2')
    const acceptableConform = this.getFieldValue('relevancia_3')

    contentHtml = contentHtml + this.addStartLine('bt')

    contentHtml =
      contentHtml +
      this.generateOnlyCircleChartContent({
        value: requiredConform,
        label: 'Conformidades obrigatórias',
        sufixo: '#dd2c00',
        fieldName: 'relevancia_1',
        props: '',
      })

    contentHtml =
      contentHtml +
      this.generateOnlyCircleChartContent({
        value: relevantConform,
        label: 'Conformidades relevantes',
        sufixo: '#ff6f00',
        fieldName: 'relevancia_2',
        props: 'bl',
      })

    contentHtml =
      contentHtml +
      this.generateOnlyCircleChartContent({
        value: acceptableConform,
        label: 'Conformidades aceitáveis',
        sufixo: '#1b5e20',
        fieldName: 'relevancia_3',
        props: 'bl',
      })

    contentHtml = contentHtml + this.addEndLine()

    return contentHtml
  }

  recursive(callback) {
    for (const step of this.structure.steps) {
      this.recursiveFields(step.fields, callback)
    }
  }
}
